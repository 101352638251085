// import enLocale from 'element-ui/lib/locale/lang/en' //引入element语言包
const en = {
  login: { // 登录页面
    dlym01: 'Welcome Sign In',
    dlym02: 'No account yet?',
    dlym03: 'Register now',
    dlym04: 'Enter username',
    dlym05: 'Enter password',
    dlym06: 'Remember password',
    dlym07: 'Forget password',
    dlym08: 'SIGN IN',
    dlym09: 'SIGNING',
    dlym10: '登录成功', // 未翻译
    dlym11: 'No data'
  },
  register: { // 注册页面
    zcym01: 'Register',
    zcym02: 'Already have an account',
    zcym03: 'Log in',
    zcym04: 'Enter username',
    zcym05: 'Enter password',
    zcym06: 'Comfirm password',
    zcym07: 'Referral code(optional)',
    zcym08: 'REGISTER',
    zcym09: 'Customer Service',
    zcym10: '注册中...', // 未翻译
    zcym11: '请输入6至20位小写字母, 大写字母和数字的组合', // 未翻译
    zcym12: '两次密码设置不一致。' // 未翻译
  },
  vip: {
    vip01: 'VIP Details',
    vip02: 'total bets',
    vip03: 'weekly bets',
    vip04: 'weekly bonus',
    vip05: 'Cashback  Proportion',
    vip06: 'game type',
    vip07: 'game',
    vip08: 'cashback'
  },
  yjjlbl: {
    yjjlbl01: 'Commission Proportion',
    yjjlbl02: 'subordinates vip',
    yjjlbl03: 'your  commission'
  },
  szcmlx: {
    szcmlx01: 'Set chip type',
    szcmlx02: 'USDT Chip',
    szcmlx03: 'RMB Chip',
    szcmlx04: "don't prompt today",
    szcmlx05: "don't prompt in 7 days ",
    szcmlx06: 'commit'
  },
  yqhy: {
    yqhy01: 'TG share link',
    yqhy02: 'Web share link',
    yqhy03: 'Share code',
    yqhy04: 'up to ',
    yqhy05: ' bets cashback',
    yqhy06: 'copy',
    yqhy07: 'Note:friends click your share link will be your  direct subordinates',
    yqhy08: 'New Direct subordinates',
    yqhy09: 'Direct subordinates',
    yqhy10: 'copy sucess',
  },
  contact: {
    lxkf01: 'Online Service',
    lxkf02: 'Telegram',
    lxkf03: 'Official Channel',
    lxkf04: 'Consult',
    lxkf05: 'TG VIP客服'
  },
  bottomTab: {
    zmk01: 'Home',
    zmk02: 'Promotion',
    zmk03: 'Referral',
    zmk04: 'Account',
    zmk05: '请登录', // 未翻译
    zmk06: 'Recomand',
    zmk07: 'Menu'
  },
  home: { // 首页
    sy01: 'SIGN IN',
    sy02: 'SIGN OUT', // 有误
    sy03: 'Deposit',
    sy04: 'Withdraw',
    sy05: 'Task',
    sy06: 'My Income',
    sy07: 'Invite',
    sy08: 'My Bets',
    sy09: 'Hot Games',
    sy10: 'Slots',
    sy11: 'Table Games',
    sy12: 'Live Casino',
    sy13: 'Sports',
    sy14: 'Instant Hash',
    sy15: '1min-Hash',
    sy37: "Don't pop up again",
    sy16: '',
    sy17: '',
    sy18: '',
    sy19: '',
    sy20: '',
    sy21: '',
    sy22: '',
    sy23: '',
    sy24: '',
    sy25: '',
    sy26: '',
    sy27: 'Select filter item',
    sy28: 'Game searching',
    sy29: 'Buy Crypto',
    sy30: 'Recommend VPN',
    sy31: 'HTX',
    sy32: 'BINANCE',
    sy33: 'OKEX',
    sy34: 'ZB',
    sy35: 'Partners and Industry Associations',
    sy36: 'Legal Gaming License',
    sy38: 'Add to home screen', // 未翻译
    sy39: 'All',
    sy40: 'Hot'
  },
  czym: { // 充值页面
    czym01: 'Deposit',
    czym02: 'Account Deposit',
    czym03: 'Supported Crypto',
    czym04: 'USDT',
    czym05: 'USD-HUIONE',
    czym06: 'BYEX',
    czym07: 'Wallet Address',
    czym08: 'Copy Address',
    czym09: 'Copied',
    czym10: 'Minimun deposit amount',
    czym111: 'Tip:',
    czym11: 'Make sure that the network you use for trading is the TRON network and the protocol used is the TRC20 protocol。',
    czym12: 'Select payment way',
    czym121: '', // 未翻译
    czym13: 'HUIONE Transfer',
    czym14: 'HUIONE Scan',
    czym15: 'Account name',
    czym16: 'Account no',
    czym17: 'Copy',
    czym18: 'Contact online service to top up',
    czym19: 'Please transfer money to the above account through the Huione APP  transfer function.',
    czym20: 'USD top-up amount is converted to USDT 1:1, minimum top-up amount is $1.',
    czym21: 'Please enter the recharge amount',
    czym22: 'You can choose the recharge amount',
    czym23: 'comfirm',
    czym24: 'Recharge Amount',
    czym25: 'This QR code is valid util Beijing time:',
    czym26: 'The QR code is valid for 10 minutes, please scan the code to complete the recharge through the HUIONE APP  before it expires.',
    czym27: 'Return',
    czym28: 'USD top-up amount is converted to USDT 1:1, minimum top-up amount is $10.',
    czym29: 'Account name',
    czym30: 'Account no',
    czym31: 'Copy',
    czym32: 'Contact online service to top up',
    czym33: 'Please transfer money to the above account through the BYEX APP  transfer function.',
    czym34: 'USD top-up amount is converted to USDT 1:1, minimum top-up amount is $10.',
    czym35: '充值成功', // 未翻译
    czym36: 'Pay',
    czym37: 'Pay by qrcode',
    czym38: 'currently you are a restricted user. Please contact online ',
    czym39: 'customer service',
    czym40: ' to change your user status before recharging, in order to activate withdrawal, cashback and other functions!',
  },
  txym: { // 提现页面
    txym01: 'Withdraw',
    txym02: 'Web Withdraw',
    txym03: 'TG Withdraw',
    txym04: 'Account Withdraw',
    txym05: 'Supported crypto',
    txym06: 'USDT',
    txym07: 'USD-HUIONE',
    txym08: 'BYEX',
    txym09: 'Your wallet address',
    txym10: 'Add address',
    txym11: 'Withdraw amount',
    txym12: 'Lefted bet amount',
    txym13: 'Minimun withdraw amount',
    txym14: 'All',
    txym15: 'Account balance',
    txym16: 'Withdraw password',
    txym17: 'Set withdraw Password',
    txym18: 'Minimum withdraw amount is 10U, single withdraw fee is 0U',
    txym19: 'You can withdraw cash only when lefted bet amount is 0, otherwise you need to complete the lefted bet amount',
    txym20: 'Withdraw Money',
    txym21: 'HUIONE account name',
    txym22: 'Please enter huione name(e.g.',
    txym23: 'HUIONE account no',
    txym24: 'Please enter huione no(e.g.',
    txym25: 'Withdraw amount',
    txym26: 'Lefted bet amount',
    txym27: 'Minimum withdraw amount',
    txym28: 'All',
    txym29: 'Account balance',
    txym30: 'Withdraw password',
    txym31: 'Set withdrawal Password',
    txym32: 'Minimum withdraw amount is 10U, single withdraw fee is 0U',
    txym33: 'You can withdraw cash only when lefted bet amount is 0, otherwise you need to complete the lefted bet amount',
    txym34: 'Withdraw Money',
    txym35: 'BYEX account name',
    txym36: 'Please enter BYEX name(eg.',
    txym37: 'BYEX account no',
    txym38: 'Please enter BYEX no(eg.',
    txym39: 'Withdraw amount',
    txym40: 'Lefted bet amount',
    txym41: 'Minimum withdraw amount',
    txym42: 'All',
    txym43: 'Account balance',
    txym44: 'Withdraw password',
    txym45: 'Set withdrawal Password',
    txym46: 'Minimum withdraw amount is 10U, single withdraw fee is 0U',
    txym47: 'You can withdraw cash only when lefted bet amount is 0, otherwise you need to complete the lefted bet amount',
    txym48: 'Withdraw Money',
    txym49: 'Account Withdraw',
    txym50: 'Supported crypto',
    txym51: 'Account balance',
    txym52: 'Minimum withdraw amount is 10U, single withdraw fee is 0U',
    txym53: 'Ensure the safe arrival of the funds, please withdraw the funds through the official telegram robot',
    txym54: 'Withdraw(Will Open TG)',
    txym55: 'Enter your withdrawal password',
    txym56: 'Confirm your withdrawal password',
    txym57: 'Please enter email verification code'
  },
  rwzx: { // 任务中心
    rwzx01: 'Task',
    rwzx02: 'Attend',
    rwzx03: 'Claim',
    rwzx04: 'Unavailable'
  },
  wdsr: { // 我的收入
    wdsr01: 'My Income',
    wdsr02: 'Direct Performance',
    wdsr03: 'Cashback rewards',
    wdsr04: 'Claim',
    wdsr05: 'Claimed',
    wdsr06: 'No cashback rewards',
    wdsr07: '1% bets cashback',
    wdsr08: 'All bets',
    wdsr09: 'Valid bets',
    wdsr10: 'Cashback settlement',
    wdsr11: 'Details',
    wdsr12: 'Cashback records',
    wdsr13: 'Apply time',
    wdsr14: 'Amount',
    wdsr15: 'Status',
    wdsr16: 'No More',
    wdsr17: 'Yday rewards',
    wdsr18: 'New rewards',
    wdsr19: 'Total rewards',
    wdsr20: 'Commission rewards',
    wdsr21: 'Claim',
    wdsr22: '0.4% bets commission',
    wdsr23: 'Direct subordinates',
    wdsr24: 'New Direct subordinates',
    wdsr25: 'Commission  settlement',
    wdsr26: 'Details',
    wdsr27: 'Yday rewards',
    wdsr28: 'New rewards',
    wdsr29: 'Total rewards',
    wdsr30: 'Direct performance',
    wdsr31: 'Details',
    wdsr32: 'Commission records',
    wdsr33: 'Today performance',
    wdsr34: "Agency commission is settled on a daily basis, and yesterday's commission is  settled after 2am Singapore time",
    wdsr35: 'Performance query',
    wdsr36: 'Game type',
    wdsr37: 'Performance',
    wdsr38: 'Proportion',
    wdsr39: 'Hash',
    wdsr40: 'Slots',
    wdsr41: 'Table Games',
    wdsr42: 'Live Casino',
    wdsr43: 'Sports',
    wdsr44: 'Toady',
    wdsr45: 'Yesterday',
    wdsr46: 'Name',
    wdsr47: 'Amount',
    wdsr48: 'TG no',
    wdsr49: 'up to ',
    wdsr50: '% bets cashback >',
    wdsr51: 'up to ',
    wdsr52: '% bets  commission >'
  },
  tzjl: { // 投注记录
    tzjl01: 'My Bets Records',
    tzjl02: 'Total bets',
    tzjl03: 'Bets',
    tzjl04: 'Profit',
    tzjl05: 'Bet Amount',
    tzjl06: 'Vaild Bets',
    tzjl07: 'Bets record',
    tzjl08: 'Hash Games',
    tzjl09: 'Slots',
    tzjl10: 'Table Games',
    tzjl11: 'Live Casino',
    tzjl12: 'Sports',
    tzjl13: 'Bet details',
    tzjl14: 'Total bets',
    tzjl15: 'Vaild bets',
    tzjl16: 'Profit',
    tzjl17: 'Total bets',
    tzjl18: 'Vaild bets',
    tzjl19: 'Profit',
    tzjl20: 'Bet no',
    tzjl21: 'Participated game',
    tzjl22: 'Betting area',
    tzjl23: 'Winning situation',
    tzjl24: 'Betting amount',
    tzjl25: 'Payout amount',
    tzjl26: 'Issued no',
    tzjl27: 'Wallet address',
    tzjl28: 'Hash key',
    tzjl29: 'Bet time',
    tzjl30: 'Verify',
    tzjl31: 'Total bets',
    tzjl32: 'Vaild bets',
    tzjl33: 'Profit',
    tzjl34: 'Bet no',
    tzjl35: 'Participated game',
    tzjl36: 'Bet time',
    tzjl37: 'Betting amount',
    tzjl38: 'Valid bets',
    tzjl39: 'Winning situation',
    tzjl40: 'Settle or not',
    tzjl41: '开奖号码'
  },
  mywd: { // 我的
    wd01: 'Invite',
    wd02: 'My Income',
    wd03: 'My Bets',
    wd04: 'Security',
    wd05: 'Deposit',
    wd06: 'Withdraw',
    wd07: 'Total balance',
    wd08: 'Red Packet',
    wd09: 'Code Red Packet',
    wd10: 'Profit Report',
    wd11: 'Balance Record',
    wd12: 'Transation',
    wd13: 'Affiliate',
    wd14: 'Buy Crypto',
    wd15: 'Vpn Download',
    wd16: 'Log out',
    wd17: 'Task'
  },
  aqzx: { // 安全中心
    aqzx01: 'Comprehensive protection of your account security',
    aqzx02: 'Set log in account',
    aqzx03: 'Set email',
    aqzx04: 'Modify email',
    aqzx05: 'Modify  log in password',
    aqzx06: 'Set withdraw password',
    aqzx07: 'Modify withdraw password',
    aqzx08: 'Bind withdraw wallet address',
    aqzx09: 'Please enter log in name',
    aqzx10: 'Please enter log in password',
    aqzx11: 'Please  comfirm log in password',
    aqzx12: 'Send',
    aqzx13: 'Please enter TG verification code',
    aqzx14: 'Please enter email',
    aqzx15: 'Please enter email verification code',
    aqzx16: 'Please enter log in password',
    aqzx17: 'In order to protect your account and fund security, please bind the email',
    aqzx18: 'Please enter new email',
    aqzx19: 'Commit',
    aqzx20: 'Please enter old  withdraw password',
    aqzx21: 'Please enter new  withdraw password',
    aqzx22: 'Please comfirm new  withdraw password',
    aqzx23: 'Commit',
    aqzx24: 'Forget password',
    aqzx25: 'Please enter old  log in password',
    aqzx26: 'Please enter new  log in password',
    aqzx27: 'Please comfirm new  log in password',
    aqzx28: 'Commit',
    aqzx29: 'Forget password',
    aqzx30: 'Add wallet address',
    aqzx31: 'Binded wallet address',
    aqzx32: 'Add new address',
    aqzx33: 'Add new address',
    aqzx34: 'Select protocol',
    aqzx35: 'Add address',
    aqzx36: 'Please enter wallet address',
    aqzx37: 'Hint: For the safety of your funds, please make sure that the address you add is the same as the selected protocol.',
    aqzx38: 'Comfirm',
    aqzx39: 'Forget withdraw password',
    aqzx40: 'Please enter email'
  },
  wdhb: { // 我的红包
    hb01: 'Total balance',
    hb02: 'Send',
    hb03: 'Total amount',
    hb04: 'Number',
    hb05: 'Type',
    hb06: 'Bets need',
    hb07: 'Deadline',
    hb08: 'Lefted amount',
    hb09: 'Details',
    hb10: 'Red packet link',
    hb11: 'TG red packet link',
    hb12: 'Send TG red packet message',
    hb13: 'Notes',
    hb14: 'Send',
    hb15: 'Total amount',
    hb16: 'Number',
    hb17: 'Type',
    hb18: 'Turnover times',
    hb19: 'Deadline',
    hb20: 'Notes',
    hb21: 'Comfirm',
    hb22: 'Newcomer',
    hb23: 'All person',
    hb24: '1x',
    hb25: '2x',
    hb26: '3x',
    hb27: '4x',
    hb28: '5x',
    hb29: '1 day later',
    hb30: '2 day later',
    hb31: '3 day later',
    hb32: 'Newcomer red packet: all newcomers can claim; all person red packet: everyone can claim',
    hb33: 'times',
    hb34: 'no',
    hb35: 'share link'
  },
  hbkl: { // 红包口令
    hbkl01: 'Enter the code,pick up the red packet',
    hbkl02: 'Pick up'
  },
  sylb: { // 输赢列表
    sybb01: 'Statistics',
    sybb02: 'Time',
    sybb03: 'Bets',
    sybb04: 'Vaild Bets',
    sybb05: 'Profit',
    sybb06: 'Total Bets',
    sybb07: 'Total Profit',
    sybb08: 'The report is calculated based on the games profit and cashback'
  },
  ctjl: { // 充提记录
    ctjl01: 'Balance Record',
    ctjl02: 'Total balance',
    ctjl03: 'Order no',
    ctjl04: 'Initiation type',
    ctjl05: 'Recharge currency',
    ctjl06: 'Protocol',
    ctjl07: 'Service fee',
    ctjl08: 'Initiation amount',
    ctjl09: 'Credited amount',
    ctjl10: 'Transation hash',
    ctjl11: 'Initiation time',
    ctjl12: 'Withdraw  currency'
  },
  jyjl: { // 交易记录
    jyjl01: 'Transation Record',
    jyjl02: 'Balance changes',
    jyjl03: 'Deposit',
    jyjl04: 'Withdraw',
    jyjl05: 'Bonus'
  },
  gmhb: { // 购买货币
    gmhb01: 'Buy Crypto',
    gmhb02: 'Exchage',
    gmhb03: 'Download link',
    gmhb04: 'Turorial',
    gmhb05: 'View'
  },
  tjvpn: { // 推荐vpn
    tjvpn01: 'Software Downloads',
    tjvpn02: 'VPN Recommendations',
    tjvpn03: 'Mango VPN',
    tjvpn04: 'Nord VPN',
    tjvpn05: 'Download',
    tjvpn06: 'Official Recommended Wallet',
    tjvpn07: 'In addition to  recommended exchanges, it is also possible to transfer money through decentralized wallets',
    tjvpn08: 'Other Downloads',
    tjvpn09: 'Official TG Robot',
    tjvpn10: 'TG Robot Turorial',
    tjvpn11: 'TG Robot'
  },
  hxhash: { // 哈希
    hx01: 'Integrated Hash',
    hx02: '1.95~9.7 times lottery  return',
    hx03: 'The last 1 digit (ignoring letters and decimal points) is used as the result of the lottery.',
    hx04: 'Issued no',
    hx05: 'Beted no',
    hx06: 'More issued no',
    hx07: 'Current bets',
    hx08: 'My bets',
    hx09: 'Game rules',
    hx10: 'limit',
    hx11: 'BIG',
    hx12: 'SMALL',
    hx13: 'ODD',
    hx14: 'EVEN',
    hx15: 'B&O',
    hx16: 'S&E',
    hx17: 'B&E',
    hx18: 'S&O',
    hx19: 'Enter the amount',
    hx20: 'Comfirm',
    hx21: 'Reset',
    hx22: 'Keep bets',
    hx23: 'Bets',
    hx24: 'Round',
    hx25: 'The top of the table is the latest data, the number represents the number of times the number in the column has not appeared, the red numbers are the current lottery numbers',
    hx26: 'B&O means big and odd,S&E means small and even,B&E means big and even,S&O means small and odd,O&E means odd and even,B&S means big and small',
    hx27: 'Number Trend',
    hx28: 'O&E Trend',
    hx29: 'B&S Trend',
    hx30: 'Successful bet',
    hx31: 'Beted no：',
    hx32: 'Sorry, you lost',
    hx33: "This period's result",
    hx34: 'Lottery no',
    hx35: 'Details',
    hx36: 'Hash key',
    hx37: 'Verify',
    hx38: 'I see',
    hx39: 'Manual mode',
    hx40: 'Betting area',
    hx41: 'Issued no',
    hx42: 'Betting amount',
    hx43: 'Winning amount',
    hx44: 'Verify hash key',
    hx45: 'Video leads how to verify hash key',
    hx46: 'View',
    hx47: 'Issued time',
    hx48: 'Issued no',
    hx49: 'Wallet address',
    hx50: 'Hash key',
    hx51: 'Issued status',
    hx52: "The platform relies on a decentralized blockchain network to randomly generate the wallet address data for the next 24 hours and 28,800 issues(3 seconds a period) as the source of the issues, and after the issues the wallet private key is published so that the players can verify it, so that the platform can't manipulate the data, and all the records are transparent and traceable.",
    hx53: 'Click copy wallet private key hash to open any decentralized wallet, import the wallet private key hash, check if the wallet address is the same as the wallet address given by the platform！',
    hx54: 'Issues query',
    hx55: 'Issued no',
    hx56: 'Enter issued no or wallet address',
    hx57: 'How to verify',
    hx58: 'Issued time',
    hx59: 'Issued no',
    hx60: 'Wallet address',
    hx61: 'Hash key',
    hx62: 'Unissued no',
    hx63: 'Click on the lottery issue number, wallet address, and lottery private key hash content to copy it',
    hx64: 'Total bets',
    hx65: 'Bets',
    hx66: 'Vaild bets',
    hx67: 'Profit',
    hx68: 'Bet no',
    hx69: 'Participated game',
    hx70: 'Betting area',
    hx71: 'Winning situation',
    hx72: 'Betting amount',
    hx73: 'Payout amount',
    hx74: 'Issued no',
    hx75: 'Wallet address',
    hx76: 'Hash key',
    hx77: 'Bet time',
    hx78: 'Verify',
    hx79: 'Hash Even/Odd',
    hx80: 'Hash Bullfight',
    hx81: 'Hash Big/Small',
    hx82: 'Hash Banker/Player',
    hx83: 'Luckey Hash',
    hx84: 'Dice Even/Odd',
    hx85: 'Dice Big/Small',
    hx86: 'Bull1',
    hx87: 'Bull2',
    hx88: 'Bull3',
    hx89: 'Bull4',
    hx90: 'Bull5',
    hx91: 'Bull6',
    hx92: 'Bull7',
    hx93: 'Bull8',
    hx94: 'Bull9',
    hx95: 'BullBull',
    hx96: 'BULL PLAYER',
    hx97: 'Tie',
    hx98: '2times lottery  return',
    hx99: '1.95times lottery  return',
    hx100: 'key hash value from right to left read 3 (1-6) numbers (repeatable) for each lottery number as the lottery result.',
    hx101: 'Deposit',
    hx102: 'B',
    hx103: 'S',
    hx104: 'O',
    hx105: 'E',
    hx106: 'Congratulations,you win',
    hx107: "This period's result",
    hx108: 'Direct mode'
  },
  yxgz: { // 游戏规则
    yxgz01: 'Lottery rules',
    yxgz02: 'In order to ensure the absolute fairness of the game,the platform in advance to randomly generate the next 24 hours',
    yxgz03: 'data (3 seconds a period), in order to ensure that the bet is unissued period, the system issue of interval of 20 period,the wallet private key hash (KEY hash) as a result of the lottery number!',
    yxgz04: ' 28800 period of the opening wallet address ',
    yxgz05: 'In order to ensure the absolute fairness of the game,the platform in advance to randomly generate ',
    yxgz06: 'next 24 hours 28800 period of the opening wallet address',
    yxgz07: '...,Based on the private key hash value from right to left read 3 (1-6) numbers (repeatable) for each lottery number, as the lottery result.',
    yxgz08: "1.The last 5 digits of the key hash value (key hash)  will be the card face, for example: key hash value (00057aba), the card face is '57aba', the letters are '10', and the sum of the individual digits is compared",
    yxgz09: '2.Card type algorithm: Ox1Ox10 corresponds to 110 times',
    yxgz10: "3.When the player's card type is Ox10, Ox9, a 10% service fee will be deducted when winning",
    yxgz11: "4.If the current banker is: 57a. 5+7+10=22 'Ox2'",
    yxgz12: "5.If the current player is: aba. 10+10+10=30 'Ox10'",
    yxgz13: '6.If the point values are the same, a 1% service fee will be deducted and the principal will be refunded',
    yxgz14: "1.The last 5 digits of the key hash value (Key hash)  will be the card face, for example: key hash value (000a3b78), the card face is 'a3b78'",
    yxgz15: "2.The banker's card face is the first 2 digits. The player's card face is the last 2 digits. 0 is the smallest, 9 is the largest, and letters (abc) are 0",
    yxgz16: '3.If the current banker is: a, 3. The value is 3',
    yxgz17: '4.If the current player is: 7, 8. The value is 5',
    yxgz18: '5.Bet on banker win, after comparing the points, if the banker wins, it will pay 1.95 times',
    yxgz19: '6.Bet on player win, after comparing the points, if the player wins, it will pay 1.95 times',
    yxgz20: '7.Bet on tie win, after comparing the points, if the tie wins, it will pay 8 times',
    yxgz21: "8.If you bet on banker or player, and it's a tie, the bet will be refunded ",
    yxgz22: '1.The last 2 digits of the private key hash (Key hash) of the opening wallet are a combination of numbers or letters for the win',
    yxgz23: "issued no 2023122500006's key hash is ",
    yxgz24: 'e.g:',
    yxgz25: 'data (3 seconds a period), in order to ensure that the bet is unissued period, the system issue of interval of 20 period,the wallet private key hash (KEY hash) as a result of the lottery number!',
    yxgz26: '1.The last 1 digit of the key hash  will be the lottery result (ignoring letters)',
    yxgz27: '2.Digits 0、1、2、3、4 are recognized as  small numbers',
    yxgz28: '3. Digits 5、6、7、8、9 are recognized as  big numbers',
    yxgz29: '2.Digits 1、3、5、7、9 are recognized as  odd numbers',
    yxgz30: 'Digits 0、2、4、6、8 are recognized as  even numbers',
    yxgz31: 'Size: The sum of three dice is even, and the sum of three dice is odd. Please note: If the first, second, and third dice have the same number of points, you will take the [Odd] and [Even] bets.',
    yxgz32: 'Size: The sum of  three dice is 3-10 for small, and the sum of three dice is 11-18 for big. Please note: If the first, second, and third dice have the same number of points, you will take the [Big] and [Small] bets.',
    yxgz33: 'Read the 3 (1-6) digits (repeatable) in order from right to left and the lottery numbers are ',
    yxgz34: 'the last 1 digit is ',
    yxgz35: '10. The special code is to choose a number for betting, the selected number and the lottery number is the same as the winning',
    yxgz36: '4.Digits 1、3、5、7、9 are recognized as  odd numbers',
    yxgz37: '5. Digits 0、2、4、6、8 are recognized as  even numbers',
    yxgz38: '6.Digits 0、2、4  are recognized as  small and even numbers',
    yxgz39: '7. Digits 6、8 are recognized as  big and even numbers',
    yxgz40: '8. Digits5、7、9  are recognized as  big and odd numbers',
    yxgz41: '9. Digits 1、3 are recognized as  small and odd numbers',
    yxgz42: 'then the lottery result is ',
    yxgz43: 'The last 2 digits are ',
    yxgz44: ',the lottery result is even',
    yxgz45: ',the lottery result is small',
    yxgz46: ', the lottery result is lucky!',
    yxgz47: '，result is big',
    yxgz48: '开奖结果为大、双、大双，特码为'
  }
  // ...enLocale //这里引入element语言包目的是在切换语言的时候，element的组件里面的文字能跟随切换
}
export default en
